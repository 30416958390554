<template>
  <svg viewBox="0 0 887.08 717.1"><path
    d="M825.1,291.6c-21.6-12.1-57.8-23.6-61.4-27.7-3.6-4.2-36.6-46.6-36.6-46.6-.8,8.6-2.1,19-2.1,19,4.1,11.8,23,43.6,30.5,50.9,7.5,7.2,60.6,21.5,62,21.2,1.4-.2,11.8-4.6,11.8-4.6l-4.2-12.2Z"
    fill="#5255cc"
  /><path
    d="M247.6,233.1s-30.6,41.1-36,45.3c-4.3,3.4-41.6,22.8-58.9,19.8-5.1-.9-15.2,31.5,1.9,33.7,0,0,63-32.6,68.1-37.6,6.1-6.1,34.2-54.5,34.2-54.5l-9.3-6.7Z"
    fill="#e5d039"
  /><path
    d="M221.5,149.9c-.6-3.8,2-7.3,5.7-7.9,3.8-.6,7.3,2,7.9,5.7l7.8,48.7-13.6,2.2-7.8-48.7Zm60.6-9.7c-.7-3.6-4.2-6.1-7.9-5.5-3.7,.6-6.2,4-5.8,7.7l7.8,48.7,13.6-2.2-7.7-48.7Z"
    fill="#ced7f8"
  /><path
    d="M258.5,186.8c33.4-5.3,61.4-4.6,62.4,1.7l1.8,11.4-60.5,9.7-3.7-22.8Z"
    fill="#1e196f"
  /><path
    d="M201.6,219.2l-1.8-11.3c-1-6.3,25.3-15.7,58.7-21l3.6,22.7-60.5,9.6Z"
    fill="#3730a3"
  /><path
    d="M260.3,198.1c-33.4,5.3-59.7,14.8-58.7,21s28.9,7.1,62.4,1.7c33.4-5.3,59.7-14.8,58.7-21-1-6.2-28.9-6.9-62.4-1.7h0Z"
    fill="#453eaf"
  /><path
    d="M219.6,279.4l-9.9-62.1c-.8-4.8,22-12.5,51-17.1s53-4.5,53.8,.4l9.9,62.1c3.1,19.4-17.8,38.9-46.7,43.5-29,4.6-55-7.4-58.1-26.8h0Z"
    fill="#6366f1"
  /><path
    d="M323.8,272.6c-2.6,8.1-10.3,15-23.8,18-21,4.6-28.1-19.5-29.4-27.8s-8.1-50.4-10-62.7c28.9-4.6,53-4.5,53.8,.4l9.9,62.1c.1,.7,.2,1.3,.2,2,.3,2.7,0,5.4-.7,8h0Z"
    fill="#5255cc"
  /><path
    d="M399.8,717.1c-10.2,0-22.5-2.4-36.3-7.2-21.8-7.7-42.2-18.9-60.3-33.3-23.5-19-34.3-38.5-30.4-54.9,2-8.3,7.5-15.4,16.5-21.4-25.73-31.61,39.41-62.49,26.8-101.1-21.1-56.5-30.4-119.3-35.9-156.8-2.5-16.7-4.4-29.9-6.3-34.7-.8-2.1,.2-4.4,2.3-5.2,2.1-.8,4.4,.2,5.2,2.3,2.1,5.6,4,17.9,6.7,36.4,5.5,37.2,14.7,99.5,35.6,155.2,16.9,45.2-53.2,78.6-26.9,99.6,8.8-4.4,20-8.1,33.4-11,127.1-27.1,135.7-97.1,135.8-97.8,.1-1.5,1-2.8,2.3-3.4s2.9-.5,4.1,.4c1.2,.9,1.8,2.3,1.6,3.8-.1,.8-2.2,19.8-21.1,42.3-31,36.9-82.9,54.4-121,62.5-9.5,1.9-18.9,4.8-27.8,8.6,9.9,7,20.9,12.4,32.5,16,59.7,18.7,96.4,45,98.2,70.4,.8,11-5.4,20.2-17.4,26-4.8,2.1-10.7,3.3-17.6,3.3h0Zm-103.8-111.6c-8.6,5.2-13.8,11.2-15.4,18-3.1,13,7,30.1,27.7,46.8,34.6,28,85.9,45.6,105.6,36.2,6.2-3,13.5-8.6,12.9-18.2-1.8-25.9-51-50.3-92.6-63.3-13.8-4.3-26.7-10.9-38.2-19.5h0Z"
    fill="#ced7f8"
  /><path
    d="M665.5,405.8l-21.6,94.9c13-2.9,23.9-1.2,18.1,24.4-5.1,22.3-28.5,125.1-28.5,125.1,7.4-1.9,65.4-102.8,96.3-176.6,27.1-65.1,3.2-85.2-64.3-67.8h0Z"
    fill="#e5d039"
  /><path
    d="M440.9,354.5l-21.6,94.9c-10.5-8.2-21.1-11.4-26.9,14.2-5.1,22.3-28.5,125.1-28.5,125.1-5.9-5-14.4-121-10.2-200.9,3.6-70.4,33.9-78.1,87.2-33.3h0Zm25.4,110.9c19.8-5.1,44-4.3,67.3,.9,23.3,5.3,45.4,15.2,61,28.3,15.6,13.2,21.8,27.1,19,39.1s-14.3,21.9-34.1,27-44.1,4.4-67.3-.9c-23.2-5.3-45.4-15.1-61-28.3s-21.8-27.1-19-39.1c2.7-11.9,14.2-21.9,34.1-27h0Z"
    fill="#fbe64b"
  /><path
    d="M594.5,494.7c-15.6-13.2-37.8-23-61-28.3s-47.5-6-67.3-.9c-9,2.3-16.2,5.6-21.8,9.6,3.3,6.2,9.8,11.3,20.5,15,71.1,24.4,84.9,57.3,80.1,74,11.5,.5,23.1-.6,34.3-3.3,19.8-5.1,31.4-15,34.1-27,2.9-12.1-3.3-25.9-18.9-39.1h0Z"
    fill="#e5d039"
  /><path
    d="M733.2,339.1C749.8,204.8,679.3,63.1,639.8,0c-62.9,39.7-187.9,136.9-231.1,265.1-21.3,63.1-17,126-8.8,177.1,2.2,14,11.7,29,28.7,43.4,23.3,19.6,56.3,34.3,90.9,42.2,34.6,7.9,70.7,9,100.2,1.4,21.6-5.6,36.6-15,44.7-26.6,29.5-42.6,60.6-97.4,68.8-163.5h0Z"
    fill="#e9ecf7"
  /><path
    d="M508.6,486.9c9.1-40-7.7-80.1-59.4-127-15.3,24.9-14.1,51.7-11.7,84.6,11.9,7.5,17,21.9,16.6,48.3,0,2.4-.1,5.9-.2,10,14.3,8,29.4,14.4,45,19.3,3.7-11.7,7-23.6,9.7-35.2h0Z"
    fill="#f1f3f6"
  /><path
    d="M589.4,533.8c10.2-.6,20.4-2.2,30.3-4.7,21.6-5.6,36.6-15,44.7-26.6,29.5-42.5,60.6-97.3,68.8-163.4C749.8,204.8,679.3,63.1,639.8,0c23.3,71.6,69.9,326.3-50.4,533.8h0Z"
    fill="#e0e7ff"
  /><path
    d="M639.8,0c-32.7,20.9-63.7,44.5-92.6,70.5-3.5,3.2-6,7.4-7.2,12-2.3,10.1,2.9,22,16.2,33.2s32.1,19.5,51.8,24,40.3,5.1,57.1,.8,26.6-12.8,28.9-22.9c1-4.7,.6-9.5-1.2-13.9C678,67.7,660.3,33,639.8,0h0Z"
    fill="#fbe64b"
  /><path
    d="M694,117.6c1-4.7,.6-9.5-1.2-13.9C678,67.8,660.3,33.1,639.9,0c8.4,25.6,19.1,76.8,25.6,140.3,17.1-4.5,26.3-13,28.5-22.7h0Z"
    fill="#e5d039"
  /><path
    d="M655.6,407c-20.3,21.7-31.7,49.7-36,82.1,10.3,9.3,19.4,22.5,13.6,48.1-5.1,22.3-28.5,125.2-28.5,125.2,5.9,4.9,56-61.7,83.8-121.8,24.4-53,18-87.2-32.9-133.6h0Z"
    fill="#fbe64b"
  /><path
    d="M669.5,436.4c12.1,16.9,31.2,42,18.2,81.9-14.5,44.4-55.7,117.2-79.6,143.1,13.1-8.6,55.6-67.4,80.4-120.9,24.5-53,18.1-87.2-32.8-133.6-1.1,1.1-.7,9.4,13.8,29.5h0Z"
    fill="#fbe64b"
  /><path
    d="M634.6,554c25.3-22.2,40.6-28.3,48.4-96.9,.7-6.2,5.4-12.4,9.1-6.7-7.6-13.9-19.5-28-36.5-43.4-20.3,21.7-31.7,49.7-36,82.1,10.3,9.3,19.4,22.5,13.6,48.1-.7,3-1.7,7.5-3,13l4.4,3.8Zm-185.4-194.1c8.9,28.3,7,58.6-3.1,89.6-13.3,3.9-27.2,11.9-33.1,37.5-5.1,22.3-28.5,125.1-28.5,125.1-7.4,1.9-23.7-79.9-22.7-146,.9-58.4,21.4-86.4,87.4-106.2h0Z"
    fill="#e5d039"
  /><path
    d="M424,380.5c-18.2,9.9-46.3,24.4-51.9,66-6.2,46.2-.6,129.8,9.7,163.5-8.1-13.5-20.9-84.8-20-143.8,.9-58.4,21.4-86.4,87.4-106.2,.4,1.3-3.4,8.6-25.2,20.5h0Z"
    fill="#fbe64b"
  /><path
    d="M551.1,202.8c26.7,21,31.4,62.4,10.6,92.5-20.8,30.1-59.4,37.4-86,16.4-26.7-21-31.4-62.4-10.6-92.5,20.8-30,59.3-37.3,86-16.4h0Z"
    fill="#f9fafb"
  /><path
    d="M550.6,286.6c16.1-23.2,12.4-55.2-8.1-71.4-4.1-3.2-8.8-5.7-13.8-7.2-17.5-3.5-36.6,4-48.3,21-16.1,23.2-12.4,55.2,8.1,71.4,4.1,3.2,8.8,5.7,13.8,7.2,17.5,3.6,36.6-4,48.3-21h0Z"
    fill="#7d80fb"
  /><path
    d="M559.3,204.9c-6.1-4.8-13-8.3-20.5-10.2l-8.3-2.1c7.5,1.9,14.5,5.4,20.5,10.2,26.7,21,31.4,62.4,10.6,92.5-15.9,23-42.1,32.7-65.5,26.6l8.2,2.1c23.4,6,49.6-3.7,65.5-26.6,21-30.1,16.2-71.5-10.5-92.5h0Z"
    fill="#ced7f8"
  /><path
    d="M480.3,229c11.8-17,30.9-24.6,48.3-21-18.6-5.8-39.8,1.5-52.6,19.9-16.1,23.2-12.4,55.2,8.2,71.4,5.3,4.2,11.4,7,18,8.3-5-1.5-9.7-4-13.8-7.2-20.5-16.2-24.1-48.2-8.1-71.4h0Z"
    fill="#ced7f8"
  /><path
    d="M492,302.9c2.1,1.3,4.3,2.5,6.6,3.5,1.2,.5,2.4,.9,3.6,1.3,.7,.1,1.5,.2,2.2,.4-4.2-1.5-8.2-3.7-11.8-6.5-20.6-16.2-24.2-48.2-8.1-71.4,11.3-16.3,29.3-23.9,46.2-21.4-.6-.2-1.3-.5-1.9-.7-.6-.2-1.5-.3-2.2-.4-.3,0-.7-.1-1-.1-.9-.1-1.9-.2-2.8-.3l-.7-.1c-1.2-.1-2.3-.1-3.5,0h-.4c-1,.1-2.1,.1-3.1,.2l-1.2,.2c-.7,.1-1.5,.2-2.2,.4-.5,.1-1,.2-1.4,.3-.7,.2-1.3,.3-2,.5s-1,.3-1.5,.4c-.5,.2-1.2,.4-1.9,.6-.6,.2-1,.4-1.5,.6-.5,.2-1.2,.5-1.8,.8s-1,.5-1.5,.7-1.2,.6-1.8,.9c-.6,.3-1,.5-1.4,.8s-1.1,.7-1.7,1.1l-1.4,.9c-.6,.4-1.1,.8-1.7,1.2l-1.3,1c-.5,.4-1.1,.9-1.6,1.4s-.8,.7-1.2,1.1l-1.6,1.6c-.4,.4-.7,.7-1.1,1.1-.6,.6-1.2,1.3-1.7,2-.3,.3-.6,.7-.9,1-.8,1-1.6,2.1-2.4,3.2-3,4.3-5.3,8.9-7,13.8-.6,1.6-1,3.2-1.4,4.8-.9,3.5-1.4,7.1-1.5,10.8-.2,3-.1,5.9,.3,8.9,.3,2.3,.7,4.7,1.3,7,1.7,7.1,5,13.7,9.6,19.4,2.1,2.5,4.4,4.8,6.9,6.8,1,.8,2,1.5,3,2.1l.5,.1Z"
    fill="#e5e7eb"
  /><path
    d="M539.3,236.3c-22.2-4.5-46.5,5.1-61.5,26.7-2.3,3.3-4.3,6.9-6,10.6,2.4,10.6,8.2,20,16.7,26.8,4.1,3.2,8.8,5.7,13.8,7.2,17.5,3.5,36.6-4,48.4-21,7.7-11.1,11.1-24.6,9.7-38.1-1.1-1-2.2-2.1-3.5-3-5.2-4.1-11.2-7.2-17.6-9.2h0Z"
    fill="#6366f1"
  /><path
    d="M780.5,635.2v-3.8h-10.3c-.1,2.3-.5,4.7-1.2,6.9,2.7,1,6.6-1.7,11.5-3.1h0Z"
    fill="#c0d0f0"
  /><path
    d="M780.5,635.2c-4.9,1.5-8.8,4.1-11.5,3.1-1.4,1-22.4,8.6-24.6,10-2.1,1.4-1.9,3.8,.4,3.8,3.4,0,31.2-1.2,31.2-1.2,.6,.6,1,1.2,1,1.2h6.4c2-3.2,1.5-13.1-2.9-16.9h0Z"
    fill="#1e196f"
  /><path
    d="M883,635.2c-.7-2-1.2-3.8-1.2-3.8l-10.6,.1c.5,2.2,.6,4.5,.2,6.8,2.7,1,6.7-1.7,11.6-3.1h0Z"
    fill="#c0d0f0"
  /><path
    d="M883,635.2c-4.9,1.5-8.8,4.1-11.5,3.1-1.4,1-22.4,8.6-24.5,10s-1.9,3.8,.4,3.8c3.4,0,31.3-1.2,31.3-1.2,.6,.6,1,1.2,1,1.2h6.4c1.8-3.2,1.3-13.1-3.1-16.9h0Zm-20.4-214.6c7.5,17.5,5.2,32.6,4,45.4,2.6,15.3,17.5,165.4,17.5,165.4-3.8,0-14.5,.1-14.5,.1,0,0-33.7-93.4-38.2-108.5-4.8-16.2-23.7-79.7-22.7-105,14.6,1.4,53.9,2.6,53.9,2.6h0Z"
    fill="#1e196f"
  /><path
    d="M795.5,418c-6.5,15.2-43.1,99.7-43.9,108.8-.8,9.1,18.1,104.6,18.1,104.6h11.1c6-41.4,5.8-95.7,2-105,15-17.7,48.7-52.2,55.2-60.9,10.2-13.7,11.8-29.7,9.8-43.7-9.9-2.4-52.3-3.8-52.3-3.8h0Z"
    fill="#3730a3"
  /><path
    d="M702.9,231.9c-1.4-2.6,.1-5.3-1.5-10.7,.2-1.6,2.3-5.8,.9-6.2-.5-.2-3.1,2.5-3.5,4.4s-.5,3.7-1.5,4.5c-2.1,1.5-10.7-2-15-2.4-3-.3-5.9,.9-7.8,3.1,.6,1.8,15.7,7.2,17.7,9.4,.9,1,1.7,2.1,2.2,3.4l8.5-5.5Z"
    fill="#c9d8f7"
  /><path
    d="M704.8,230.6s34,40.5,38.3,44.6c5.3,5.1,24.7,7.1,41.9,10.2,5.5,.7,13.8,1.7,16.9,2,2.6,.3,19,2.6,23.2,4.1,3.8,1.4,12.8,5.5,15.8,22.4,2.9,28.1,25.2,98.7,21.8,106.7s-52.4,3.4-67.7-1.4c-6-21.2-18.9-63.5-20.2-93.4,0,0-45.3-30.5-48.8-35.5-3.6-5-32.9-52.1-32.9-52.1l11.7-7.6Z"
    fill="#7d80fb"
  /><path
    d="M830.4,271.7c-1.8,3.4-5.9,13.5-5.3,19.9-4,1.1-14.5-.2-23.2-4.1,2.7-2.6,6-4.9,5.8-8.4-.3-5.5-7.6-1-8.4-9.6-.9-10,4-27.2,9.8-30.4,6.4-3.6,17.8-6.7,27.3,4.9,7.7,9.4-2.4,20.7-6,27.7h0Z"
    fill="#d0ddf8"
  /><path
    d="M809.6,253.7c-2.8-1-.3-5.7,.4-8.5,.5-1.9-1-3.8-3.2-4.4-8.1-2.1-9.2-13.9-2.7-16.3,6.5-2.4,8.9,5.1,13,5.2,4.8,.2,6-5.4,12.2-2.5,5.7,2.7,1,7.2,4.4,9.6,3.4,2.4,11.1-.6,13.6,7.2,1.9,5.8-5.5,8.3-6.7,13.5-.8,3.3-1.3,17.7-11,17.7s-15.4-10.3-12.2-19.1c1.3-3.6,1.1-6.6-.8-7-2.5-.6-3,6-7,4.6h0Z"
    fill="#1e196f"
  /><path
    d="M789.6,377.6c-4.7-25-2.2-47.1-1.8-51.7s-21.7-9.5-36.2-16c11.8,8.3,23.2,16,23.2,16,1.3,29.9,14.2,72.2,20.2,93.4,2.8-2.8-.7-16.8-5.4-41.7h0Z"
    fill="#6366f1"
  /><path
    d="M260.5,254.9c.6-1.2,5.3-3.8,8.6-8,5-6.3,8.8-15.7,3.5-18.5s-12.2,12.9-14.2,12.2c-1.8-.6-2.5-7.2-3.8-7.6-1.3-.4,.2,7.8-.7,11.5-.5,2.2,.3,4.5-.9,6.6,.6,.4,7.5,3.8,7.5,3.8h0Z"
    fill="#c9d8f7"
  /><path
    d="M176.2,642.5v-3.7h10.1c.1,2.3,.5,4.5,1.2,6.7-2.7,1-6.5-1.6-11.3-3h0Z"
    fill="#d0ddf8"
  /><path
    d="M176.2,642.5c4.8,1.4,8.6,4,11.2,3,1.4,1,21.8,8.3,23.8,9.8,2.1,1.4,1.8,3.7-.4,3.7-3.3,0-30.4-1.2-30.4-1.2-.5,.6-1,1.2-1,1.2h-6.2c-1.7-3.3-1.2-12.9,3-16.5h0Z"
    fill="#1e196f"
  /><path
    d="M11.9,602.7c1.6-1.2,3.1-2.3,3.1-2.3l8.3,6c-1.7,1.5-3,3.3-4,5.2-2.8-.7-4.4-5-7.4-8.9h0Z"
    fill="#d0ddf8"
  /><path
    d="M11.9,602.7c3.1,3.9,4.7,8.2,7.4,9,.5,1.6,12.9,19.4,13.8,21.8,.9,2.4-.7,4.1-2.5,2.8-2.7-1.9-24.1-18.6-24.1-18.6-.5,.1-1,.2-1.5,.4l-5-3.6c.3-3.7,6.3-11.3,11.9-11.8h0Z"
    fill="#1e196f"
  /><path
    d="M103.9,416.3c-10.6,15.2-15,33.5-16.4,45.9-2,5.1-5.6,31.7-9.6,52.5-27.4,21.4-63,85.6-63,85.6,3,2.1,8.3,6,8.3,6,0,0,68.3-73.6,80.4-83,11.8-23.5,37.9-75.9,42-100.2-14.2-1.5-41.7-6.8-41.7-6.8h0Z"
    fill="#d0ddf8"
  /><path
    d="M160.6,433.6c6.3,14.7,30.4,91.9,31.2,100.8,.8,8.8-5.5,104.4-5.5,104.4h-10.1c-4.2-38.6-10.8-95.7-7.1-104.8-14.5-17.1-43.5-45.7-49.9-54.2-9.9-13.3-11.5-28.8-9.5-42.4,9.7-2.5,50.9-3.8,50.9-3.8h0Zm4.7-128.9c-2.6-4.2-5-12.7-3.3-15.2,1.7-2.6,9.7-.9,11.6-4.7,2.5-5.1,4-15.1,3.4-23.2-1-11.9-4.9-19.8-18.1-18.5-10.5,1-17.7,9-17.9,19.9-.2,12.1,6.4,24.2,5.8,28.4-.3,2.4-1.7,4.5-3.8,5.7l22.3,7.6Z"
    fill="#d0ddf8"
  /><path
    d="M172.3,347.3c-6.3,9.7-14.4,14-18.4,33-5.1,24.8,11.1,56.4,31.5,118.6-56.5,23-106.9,13.1-126.8-1.6,29.1-43,22.3-55.1,32.7-82.2,5.7-14.9,16.4-25.5,20.2-40.9,4.3-17.8,6.5-57.5,20.9-72,10.6-10.7,26.3-6.2,34,3.9,8.1,10.3,5.9,41.2,5.9,41.2h0Z"
    fill="#ffed6d"
  /><path
    d="M118.3,387.2c-2.5-23.6-4.4-33.1,1.9-47.7,7.6-17.8,7.2-24.6,5.9-27.9-8.8,18-10.9,47.7-14.5,62.5-3.8,15.4-14.5,26-20.2,40.9-10.4,27.1-3.7,39.2-32.7,82.2,7.8,5.8,20.3,10.8,36,13.4h1.4c-11.8-20.9-9.2-51.8,6.8-70.6,23.2-27.1,17.5-32.9,15.4-52.8h0Z"
    fill="#fef08a"
  /><path
    d="M252,250.7s-23.1,45.8-27.7,50.9c-3.6,4-44.1,8.8-59.4,2.5-4.8-2-9.5,33.6,7.7,32.8,0,0,57.1-12.6,62.4-17.3,5.4-4.8,27.2-63.8,27.2-63.8l-10.2-5.1Z"
    fill="#ffed6d"
  /><path
    d="M154,380.2c3.9-19,12-23.2,18.4-33,0,0,.3-4.3,.3-10.3-5.3,1.3-10,.8-10.7,8-1.3,14.1-11.3,28.7-11.6,36.7s.5,25.7,6.3,37.5l3.6,6.4c-6.1-18.8-8.9-32.9-6.3-45.3h0Z"
    fill="#fbe64b"
  /><path
    d="M166.5,265.6c-7.3,2.2-12.4,5.6-13.9,13.3-1.7,8.8,6,14.7,1.1,20.8-7.1,8.7-23.2,9.4-29.8,25.9s9.5,40.5-21.1,41.5c4.2,4.7,9.9,7.7,19.2,4,23.4-9.1-2.6-33.3,23.4-51.3,23.6-16.3,18.2-26.4,15-32.3-2.9-5.3-1.6-14.9,4.1-16.5,3.7-1.1,6-2.4,7.8-4.5,2.1-2.6,3.5-7.2,1.8-16.3,.7,4.2-.7,13.3-7.6,15.4h0Z"
    fill="#1e196f"
  /><path
    d="M147.6,246.6c-12.1,8.6-10.4,23.4-7.1,30,2.3,4.8,.4,11.4-16.3,19.9-18.5,9.4-53.7,5.2-67.8,15.2,.4,23.1,15.3,40.1,28.8,38.4,8.1-1,11.3,10,17.7,17.1,30.5-1,14.5-25,21.1-41.5,6.6-16.6,22.8-17.2,29.8-25.9,5-6.1-2.8-12.1-1.1-20.8,1.5-7.6,6.5-11.1,13.9-13.3,6.8-2.1,8.2-11.2,7.7-15.3-.2-.9-.4-1.9-.6-2.9-3.9-5.9-14-9.5-26.1-.9h0Z"
    fill="#3730a3"
  /></svg>
</template>