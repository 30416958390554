<template>
  <svg viewBox="0 0 1468.03 1201.56"><path
    d="M555.42,806.41l-36.06,122.1c-4.44,15.04,4.15,30.84,19.19,35.28l144.19,42.58c15.04,4.44,30.84-4.15,35.29-19.19l36-122.1c4.45-15.04-4.14-30.83-19.17-35.28h-.02l-144.1-42.58c-15.04-4.46-30.84,4.11-35.31,19.15v.04Zm24.23,73.53c3.53-11.92,16.07-18.73,27.99-15.19s18.73,16.07,15.19,27.99c-3.53,11.91-16.04,18.72-27.96,15.2-11.93-3.52-18.75-16.04-15.23-27.97v-.03Z"
    fill="#9c9eff"
  /><path
    d="M1135.27,983.28l-10.92,36.98-45.03,7.25-15.03-4.44,3.19-10.8-15.03-4.44-6.79,23.01-33.81-9.98,4.99-16.91-25.36-7.49-9.43,31.94-54.47-16.09,3.32-11.27-30.05-8.88,4.71-15.96-36.63-10.82-8.04,27.23-38.51-11.37-8.46,28.65-29.11-8.6,5.13-17.38-32.87-9.7-3.89,13.14-1.25,4.23-32.4-9.57,33.01-111.77,382.73,113.04h0Z"
    fill="#9c9eff"
  /><path
    d="M752.64,870.21l23.95,7.07-33,111.77-23.95-7.07,33-111.77Z"
    fill="#7d80fb"
  /><path
    d="M771.19,895.59l358.79,105.94-5.51,18.66-358.79-105.94,5.51-18.66Z"
    fill="#7d80fb"
  /><path
    d="M731.54,841.08l-144.17-42.58c-8.8-2.56-18.01,2.47-20.63,11.25l-36.06,122.1c-2.59,8.82,2.44,18.07,11.25,20.68l144.17,42.58c8.82,2.59,18.07-2.44,20.68-11.25l36.06-122.1c2.59-8.83-2.46-18.09-11.29-20.68h0Zm-136.66,66.87c-11.93-3.53-18.74-16.06-15.21-28,3.53-11.93,16.06-18.74,28-15.21,11.93,3.53,18.74,16.05,15.22,27.98-3.52,11.93-16.05,18.76-27.98,15.23h-.02Z"
    fill="#7d80fb"
  /><path
    d="M1412.74,921.62l-141.82,18.14c-19.85,2.54-33.89,20.69-31.35,40.54l14.42,112.65c2.53,19.85,20.68,33.89,40.53,31.35h.01l141.82-18.14c19.85-2.52,33.9-20.66,31.39-40.51l-14.46-112.7c-2.54-19.85-20.69-33.87-40.54-31.33h0Zm-7.08,122c-14.82,1.9-28.37-8.58-30.26-23.4-1.9-14.82,8.58-28.37,23.4-30.26,14.82-1.9,28.37,8.58,30.26,23.4h0c1.88,14.81-8.6,28.34-23.4,30.23v.03Z"
    fill="#fcde06"
  /><path
    d="M689.45,1094.42l555.64-71.08,3.4,26.57-555.64,71.08-3.4-26.57Z"
    fill="#fcde06"
  /><path
    d="M689.45,1094.42l555.64-71.08,.65,5.1-555.64,71.08-.65-5.1Z"
    fill="#fef08a"
  /><path
    d="M851.72,1100.39l-19.29,2.46-19.29,2.47-19.28,2.47-19.29,2.47-19.29,2.46-19.29,2.47,11.05,86.37,19.29-2.46-1.56-12.15,19.29-2.47,19.29-2.46-2.47-19.29,19.29-2.47,4.02,31.43,19.29-2.46-1.55-12.15,19.29-2.47,1.55,12.15,19.29-2.47-11.05-86.37-19.29,2.47Zm-7.82-61.12l1.43,11.1-19.29,2.47,1.76,13.76-19.29,2.47-1.76-13.76-19.29,2.46-19.29,2.47,1.76,13.76-19.29,2.47-2.26-17.64-19.29,2.46,3.53,27.61,19.29-2.47,19.29-2.46,19.29-2.47,19.29-2.47,19.29-2.47,19.29-2.47,19.29-2.46-4.46-34.83-19.29,2.47h0Z"
    fill="#fcde06"
  /><path
    d="M736,1115.18l135.03-17.27,1.73,13.5-135.03,17.27-1.73-13.5Z"
    fill="#edd000"
  /><path
    d="M1414.23,933.27l-141.82,18.14c-13.41,1.72-22.88,13.98-21.18,27.39l14.41,112.68c1.73,13.41,13.99,22.88,27.4,21.18l141.82-18.14c13.4-1.73,22.88-13.98,21.18-27.39l-14.41-112.69c-1.73-13.4-13.99-22.88-27.4-21.17h0Zm-8.57,110.31c-14.82,1.9-28.37-8.58-30.26-23.4-1.9-14.82,8.58-28.37,23.4-30.26,14.82-1.9,28.37,8.58,30.26,23.4h0c1.89,14.82-8.58,28.37-23.4,30.26Z"
    fill="#fef08a"
  /><path
    d="M657.19,303.26v232.41c0,163.6,132.62,296.22,296.19,296.22s296.19-132.62,296.19-296.22v-232.41H657.19Z"
    fill="#e0e7ff"
  /><path
    d="M1165.43,303.26h-53.32v-91.2c0-87.53-71.21-158.74-158.73-158.74s-158.72,71.21-158.72,158.74v91.2h-53.32v-91.2C741.34,95.13,836.46,0,953.34,0s212.05,95.13,212.05,212.06l.04,91.2Z"
    fill="#6366f1"
  /><path
    d="M896.38,501.1c0-31.48,25.52-57,57-57s57,25.52,57,57-25.52,57-57,57-57-25.52-57-57h0Z"
    fill="#3730a3"
  /><path
    d="M928.64,503.95h49.47v122.55h-49.47v-122.55Z"
    fill="#3730a3"
  /><circle
    cx="741.34"
    cy="373.93"
    r="27.93"
    transform="translate(124.63 929.87) rotate(-68.8)"
    fill="#ced7f8"
  /><path
    d="M756.52,350.52l-38.62,38.61c2.14,3.28,4.94,6.08,8.22,8.22l38.62-38.62c-2.14-3.28-4.94-6.08-8.22-8.21h0Z"
    fill="#bbc9e6"
  /><circle
    cx="1165.4"
    cy="373.87"
    r="27.93"
    transform="translate(93.13 963.93) rotate(-46.61)"
    fill="#ced7f8"
  /><path
    d="M1180.62,350.52l-38.61,38.61c2.13,3.28,4.94,6.09,8.22,8.22l38.61-38.62c-2.14-3.28-4.94-6.08-8.22-8.21h0Z"
    fill="#bbc9e6"
  /><path
    d="M474.55,758.38V390.16h54.31v-51.93h-34.4v-29.61h48.4v-35.88h-48.4v-29.61h92.1v-35.88h-112v-39h-35.9v58.4h-35.92v108.72h35.88v423c-76.59,9.91-130.65,80.04-120.74,156.63,9.91,76.59,80.04,130.65,156.63,120.74s130.65-80.04,120.74-156.63c-8.15-63-57.74-112.59-120.74-120.74l.04,.02Zm-18,237.45c-54.57,0-98.81-44.24-98.81-98.81s44.24-98.81,98.81-98.81,98.81,44.24,98.81,98.81h0c0,54.57-44.22,98.8-98.78,98.81h-.03Z"
    fill="#5255cc"
  /><path
    d="M474.55,390.16h57.7v-51.94h-34.41v-29.6h48.41v-35.88h-48.41v-29.61h92.1v-35.88h-115.39v182.91h0Zm-71.77-163.51h35.89v108.73h-35.89v-108.73Z"
    fill="#453eaf"
  /><path
    d="M445.44,757.82V168.21h-6.78V758.37l6.78-.56h0Z"
    fill="#7d80fb"
  /><path
    d="M409.14,226.65v108.73h-6.37v-108.73h6.37Z"
    fill="#6366f1"
  /><path
    d="M60.74,206.46C2.74,269.52,0,370.61,0,381.27c0,25.11,49.24,94.62,49.24,94.62l11.5-269.43Z"
    fill="#edd000"
  /><path
    d="M30.61,503.21c1.73,140.74,26.13,163.33,34.53,299.74,6.88,111.2-31.38,196.15-36.16,233.7h25.57c18.79-49.82,138.64-231.75,103.34-533.42l-127.28-.02Z"
    fill="#1e196f"
  /><path
    d="M23.04,1045.95c2.14-3.01,4.12-6.13,5.94-9.35h25.57c-2.5,7.23-4,13.38-3,17-6.68,2.58-16.39-4-28.51-7.65Z"
    fill="#c9dbff"
  /><path
    d="M23.04,1045.95c12.12,3.62,21.83,10.2,28.49,7.65,3.51,2.55,55.35,21.21,60.63,24.81s4.61,9.42-1.11,9.42c-8.35,0-77.19-3-77.19-3-1.39,1.51-2.52,3-2.52,3H15.61c-4.7-8.09-3.42-32.5,7.43-41.88h0Z"
    fill="#1e196f"
  /><path
    d="M30.61,503.21c0,128.27,83.72,134,140,258.58,38.62,85.47,46.29,252.25,48,274.84h25.56c13.9-60.82,54.41-293-25.56-533.42H30.61Z"
    fill="#3730a3"
  /><path
    d="M218.74,1045.95c-.07-4.24-.07-9.35-.07-9.35h25.56c-1,7.23,2,13.38,3,17-6.68,2.58-16.41-4-28.49-7.65Z"
    fill="#c9dbff"
  /><path
    d="M218.74,1045.95c12.12,3.62,21.85,10.2,28.49,7.65,3.52,2.55,55.35,21.21,60.64,24.81s4.6,9.42-1.11,9.42c-8.36,0-77.19-3-77.19-3-1.39,1.51-2.53,3-2.53,3h-15.77c-4.69-8.09-3.42-32.5,7.47-41.88h0Z"
    fill="#1e196f"
  /><path
    d="M195.74,205.21c-17.1-11.78-36.07-16.25-37-16.32-27.2-2.14-68.85,.85-68.85,.85-36.75,13.06-45,29.71-54.64,97.32s-4.57,216.15-4.57,216.15H218.68c6.46-39.38,.82-154-3.41-223.18-16.45-45.08-12.15-62.39-19.53-74.82Z"
    fill="#fcde06"
  /><path
    d="M140.65,77.04c5.14,15,25.2,58.16,19.55,76.41-4.62,15-19.36,20.38-45.55,17.09s-36.34-47.59-42.19-59.73c-9.26-19.29,57.37-65.25,68.19-33.77Z"
    fill="#c9dbff"
  /><path
    d="M137.74,168.49c1.39,8,4.9,19.69,4.9,19.69-3.45,4.66-54.3,10.56-49.34,2.25,1.76-14.64,.59-48.61,.59-48.61l43.85,26.67Z"
    fill="#c9dbff"
  /><path
    d="M72.35,74.02c22.94-16.56,51-47.69,55.25-45.28,.78,.44-.47,2.5,.45,3,1.49,.88,9.52-19.07,21.15-14.2s12.44,39.59-8.55,59.46,4.8,31-4.23,41.61-2.11,40.42-20,44.38-26.1,17.11-27.87,5.73-16.58-31.66-19.44-51-9.48-34.51,3.24-43.7Z"
    fill="#1e196f"
  /><path
    d="M129.37,113.79c-1.13-4.47,.28-8.68,3.15-9.41s6.12,2.3,7.25,6.77-.26,8.68-3.14,9.41-6.12-2.3-7.26-6.77h0Z"
    fill="#e5e7eb"
  /><path
    d="M218.63,503.21c6.46-39.38,.82-154-3.41-223.18-.44-6-1.04-11.74-1.79-17.21l-35.08-5.19-38.18-9.62c2.44,23.15,15.9,69.09,33.06,99,10.94,19,26,79.91,16,156.25l29.4-.05Z"
    fill="#edd000"
  /><path
    d="M385.58,363.56c3.82-1.06,18.07,3.47,33.17,2.1,22.65-2.05,45.52-15.89,39.1-34.48-5.57-16.11-44.52,5.78-47.09,.23-2.19-4.74,9.93-19,8.32-22.39s-15.68,15.36-25.13,20.83c-5.56,3.22-8.69,9.41-15.4,10.81,.74,2.14,7.03,22.9,7.03,22.9h0Z"
    fill="#e5e7eb"
  /><path
    d="M203.74,219.66c6.86,33.21,34.73,119.67,56.17,129.74l-29.17,49.8c-25.39,2.13-74-113.16-86.6-143.41-11.92-38.7,47.98-80.78,59.6-36.13h0Z"
    fill="#fcde06"
  /><path
    d="M378.25,338.09c4.88,9.02,9.11,18.37,12.64,28-36.34,13.79-133.8,44.62-160.11,33.11l29.09-49.8c21.87,7.7,118.38-11.31,118.38-11.31h0Z"
    fill="#fcde06"
  /></svg>
</template>