<template>
  <svg viewBox="0 0 1840 1749.88"><defs><clipPath id="b"><rect
    width="1840"
    height="1749.88"
    fill="none"
  /></clipPath></defs><path
    d="M1020.86,196.42c96.91-11.79,181.74,30.16,189.47,93.69,4.27,35-15.81,69.26-50.87,94.94l24,46.6-73.74-20c-19.62,7.39-40.06,12.38-60.88,14.87-96.84,11.79-181.72-30.15-189.46-93.64-7.74-63.49,64.56-124.66,161.48-136.46h0Zm0,0"
    fill="#e0e7ff"
  /><path
    d="M750,70.63c-118.74,14.45-207.3,89.25-197.83,167.09,5.22,42.89,39.16,77.72,88.41,98l-14.88,62.42,81.81-45.45c25.5,3.03,51.28,2.96,76.77-.2,118.72-14.45,207.28-89.26,197.81-167.09-9.47-77.83-113.39-129.22-232.09-114.77h0Zm0,0"
    fill="#e9ecf7"
  /><path
    d="M660.46,222.04c-5.06,0-9.99-1.59-14.1-4.54-1.81-1.3-2.23-3.83-.93-5.64,1.3-1.82,3.82-2.23,5.64-.93,7.26,5.19,17.35,3.53,22.56-3.71,7.82-10.86,22.95-13.34,33.83-5.55,7.25,5.19,17.34,3.54,22.55-3.7,7.81-10.86,22.95-13.35,33.83-5.56,7.26,5.19,17.34,3.53,22.56-3.7,7.82-10.86,22.95-13.34,33.83-5.56,7.25,5.2,17.34,3.55,22.54-3.7,7.81-10.88,22.95-13.37,33.84-5.57,1.82,1.3,2.23,3.83,.93,5.65-1.3,1.82-3.83,2.24-5.65,.93-7.25-5.2-17.34-3.55-22.55,3.7-7.81,10.87-22.95,13.36-33.82,5.55-7.25-5.18-17.33-3.53-22.55,3.7-7.82,10.86-22.96,13.34-33.85,5.56-7.25-5.19-17.33-3.53-22.54,3.7-7.8,10.88-22.95,13.37-33.83,5.56-7.25-5.19-17.34-3.54-22.55,3.7-4.56,6.36-11.91,10.12-19.74,10.11h0Zm141.6,48.24c-.84,0-1.67-.26-2.36-.76l-48.23-34.64c-1.81-1.3-2.23-3.83-.92-5.65,1.3-1.82,3.83-2.23,5.64-.93l48.23,34.63c1.49,.99,2.14,2.85,1.59,4.56-.55,1.71-2.16,2.84-3.96,2.77h0Zm0,0"
    fill="#ced7f8"
  /><path
    d="M760.63,277.08c-1.53,.03-2.94-.81-3.64-2.18-.69-1.36-.55-3,.36-4.22l34.65-48.23c1.3-1.82,3.82-2.23,5.64-.93,1.81,1.3,2.23,3.82,.93,5.64l-34.62,48.24c-.76,1.07-2,1.7-3.32,1.68h0Zm0,0"
    fill="#ced7f8"
  /><path
    d="M1035.09,335.79c-18.88,2.84-38.07,3.02-57,.52,6.21,17.39,20.15,30.9,37.72,36.57,16.48,5.19,34.43,2.48,48.65-7.34,15.12-10.59,24.47-27.6,25.29-46.05-17.37,7.97-35.77,13.46-54.66,16.3h0Zm0,0"
    fill="#bbc9e6"
  /><path
    d="M1036.39,344.35c-11.62,1.76-20.49,11.29-21.41,23,16.48,5.19,34.43,2.48,48.65-7.34-4.33-10.92-15.62-17.42-27.24-15.66h0Zm0,0"
    fill="#e0e7ff"
  /><path
    d="M1056.14,282.13c-1.2-8,1.93-15.06,7-15.83,5.07-.77,10.16,5.08,11.36,13.06,1.2,7.98-1.93,15.07-7,15.83-5.07,.76-10.16-5.08-11.36-13.06h0Zm-56.99,8.61c-1.21-8-6.29-13.83-11.37-13.06-5.08,.77-8.21,7.85-7,15.83,1.21,7.98,6.3,13.82,11.37,13.06,5.07-.76,8.2-7.85,7-15.83h0Zm0,0"
    fill="#bbc9e6"
  /><path
    d="M1375.8,598.47c-17-20.87-42.1-15.47-61.17,.48-28.85,24.13-116.73,73.82-143.87,78.86-37.66,7-183.46-11.9-195.86-18.51-12.4-6.61-14.84-11.63-20.38-15.11-2.13-1.44-4.18-3-6.13-4.67-.94,4.32-3.49,14.72-3.49,14.72-10.51-2.34-60.1-5-56,6,4.1,11,22.89,34.75,52.28,37.9,19.65,2.11,38.22-5.47,50.38-2.39,69.1,17.49,148.08,38.35,191,29.66,54.34-10.14,152.59-60,172.37-75.57,19.78-15.57,31.15-38.72,20.87-51.37h0Zm0,0"
    fill="#c0d0f0"
  /><path
    d="M1375.57,395.41c-13.62-11.36-70.66-25.8-95.9,8.77-25.24,34.57-14.74,136.8,10.33,196.4,14.27-7.18,63.21-13.63,63.21-13.63l22.36-191.54Zm0,0"
    fill="#1e196f"
  /><path
    d="M1457,840.4c-2.79-141.32-3.9-223.5-37.8-276.75-10.3-16.17-14.79-12.72-22.08-12.72-17.79,11.12-47.5,10.64-64.14,6.8-.2,1.18-.59,2.31-1.17,3.36-24.15,42.39-81.72,113.55-59,141.73,32,39.67,55,73.7,48.68,142.86l135.51-5.28Zm0,0"
    fill="#6366f1"
  /><path
    d="M1397.07,550.93c-10.29-24.12-7.55-47.67-3-63.63,0,0,9.58-22.88-.3-56.17-12.6-42.5-71.42-49.68-96.15-27.4-20.89,21.62-17.64,36.68-14.9,67.68,0,0-4.36,30.76-4.08,32.67,.39,2.78,8.74,.23,9.47,1.44,.73,1.21,2.53,18.63,9.51,24.39,6.98,5.76,25.58-3.76,28.89,1.4,2.46,3.83,8,17.76,6.4,26.42,16.66,3.84,46.37,4.32,64.16-6.8h0Zm0,0"
    fill="#c9d8f7"
  /><path
    d="M1073.93,634.24l-69.01-94.19-274.52-29.69c-9.23-1-17.57,6.4-18.66,16.51h0l-48.28,446.52,369.47,39.95,41-379.11h0Z"
    fill="#dee1ed"
  /><path
    d="M997.57,607.53c-1.09,10.11,5.53,19.13,14.77,20.13l61.6,6.66-69.01-94.19-7.29,67.41h-.07Z"
    fill="#edf1f9"
  /><path
    d="M749.01,617.02l167,18.06-2.05,18.94-167-18.06,2.05-18.94Zm-2.85,26.32l275.28,29.77-2.05,18.94-275.28-29.77,2.05-18.94Zm10.4-86.5l128.58,13.9-2.05,18.94-128.58-13.9,2.05-18.94Zm-3.18,26.21l167.07,18.07-2.05,18.94-167.07-18.07,2.05-18.94Z"
    fill="#edf1f9"
  /><path
    d="M717.12,880.66h282.77v54.36c0,11.21-9.1,20.31-20.31,20.31h-242.15c-11.21,0-20.31-9.1-20.31-20.31v-54.36h0Z"
    transform="translate(103.67 -86.98) rotate(6.17)"
    fill="#edf1f9"
    stroke="#edf1f9"
    stroke-miterlimit="10"
    stroke-width="2.17"
  /><path
    d="M758.64,696.31h242.15c11.21,0,20.31,9.1,20.31,20.31v58.66h-282.77v-58.66c0-11.21,9.1-20.31,20.31-20.31Z"
    transform="translate(84.2 -90.31) rotate(6.17)"
    fill="#edf1f9"
    stroke="#edf1f9"
    stroke-miterlimit="10"
    stroke-width="2.17"
  /><path
    d="M732.85,882.52l254.27,27.5-2.05,18.94-254.27-27.5,2.05-18.94Zm-2.85,26.38l239.05,25.85-2.05,18.94-239.05-25.85,2.05-18.94Z"
    fill="rgba(63,62,150,.1)"
  /><path
    d="M753.39,700.7l254.27,27.5-2.05,18.94-254.27-27.5,2.05-18.94Zm-2.85,26.38l239.05,25.85-2.05,18.94-239.05-25.85,2.05-18.94Z"
    fill="rgba(63,62,150,.1)"
  /><rect
    x="731.32"
    y="776.97"
    width="94.56"
    height="20.49"
    transform="translate(89.15 -79.14) rotate(6.17)"
    fill="#edf1f9"
    stroke="#edf1f9"
    stroke-miterlimit="10"
    stroke-width="2.17"
  /><rect
    x="729.12"
    y="797.34"
    width="94.56"
    height="20.49"
    transform="translate(91.32 -78.79) rotate(6.17)"
    fill="#edf1f9"
    stroke="#edf1f9"
    stroke-miterlimit="10"
    stroke-width="2.17"
  /><rect
    x="726.92"
    y="817.7"
    width="94.56"
    height="20.49"
    transform="translate(93.5 -78.43) rotate(6.17)"
    fill="#edf1f9"
    stroke="#edf1f9"
    stroke-miterlimit="10"
    stroke-width="2.17"
  /><rect
    x="724.71"
    y="838.07"
    width="94.56"
    height="20.49"
    transform="translate(95.68 -78.08) rotate(6.17)"
    fill="#edf1f9"
    stroke="#edf1f9"
    stroke-miterlimit="10"
    stroke-width="2.17"
  /><rect
    x="825.43"
    y="787.15"
    width="94.56"
    height="20.49"
    transform="translate(90.79 -89.2) rotate(6.17)"
    fill="#edf1f9"
    stroke="#edf1f9"
    stroke-miterlimit="10"
    stroke-width="2.17"
  /><rect
    x="823.23"
    y="807.51"
    width="94.56"
    height="20.49"
    transform="translate(92.96 -88.85) rotate(6.17)"
    fill="#edf1f9"
    stroke="#edf1f9"
    stroke-miterlimit="10"
    stroke-width="2.17"
  /><rect
    x="821.03"
    y="827.88"
    width="94.56"
    height="20.49"
    transform="translate(95.14 -88.49) rotate(6.17)"
    fill="#edf1f9"
    stroke="#edf1f9"
    stroke-miterlimit="10"
    stroke-width="2.17"
  /><rect
    x="818.83"
    y="848.25"
    width="94.56"
    height="20.49"
    transform="translate(97.32 -88.14) rotate(6.17)"
    fill="#edf1f9"
    stroke="#edf1f9"
    stroke-miterlimit="10"
    stroke-width="2.17"
  /><rect
    x="919.34"
    y="797.3"
    width="94.56"
    height="20.49"
    transform="translate(92.42 -99.24) rotate(6.17)"
    fill="#edf1f9"
    stroke="#edf1f9"
    stroke-miterlimit="10"
    stroke-width="2.17"
  /><rect
    x="917.13"
    y="817.67"
    width="94.56"
    height="20.49"
    transform="translate(94.6 -98.88) rotate(6.17)"
    fill="#edf1f9"
    stroke="#edf1f9"
    stroke-miterlimit="10"
    stroke-width="2.17"
  /><rect
    x="914.93"
    y="838.04"
    width="94.56"
    height="20.49"
    transform="translate(96.78 -98.53) rotate(6.17)"
    fill="#edf1f9"
    stroke="#edf1f9"
    stroke-miterlimit="10"
    stroke-width="2.17"
  /><rect
    x="912.73"
    y="858.4"
    width="94.56"
    height="20.49"
    transform="translate(98.95 -98.18) rotate(6.17)"
    fill="#edf1f9"
    stroke="#edf1f9"
    stroke-miterlimit="10"
    stroke-width="2.17"
  /><path
    d="M1397.29,576c-24.56-11-44.55,5.21-54.36,28-14.85,34.56-70.82,118.58-92.76,135.33-30.45,23.25-169.07,72.23-183.11,71.93-14.04-.3-18.49-3.68-25-4.28-2.9-.27-23.74-1.68-23.7,.51,.04,2.19,20.9,5.07,20.5,10.6-.4,5.53-56.69,24.92-48.11,32.84,8.58,7.92,36.12,20.66,63.76,10.2,18.49-7,31.64-22.14,43.87-24.88,69.56-15.6,149.45-32.65,183.81-59.79,43.91-33.58,109.07-122.42,119.67-145.26,10.6-22.84,10.31-48.55-4.57-55.2h0Zm0,0"
    fill="#c9d8f7"
  /><path
    d="M1286.12,419.05c21.75-.45,36.62,1.54,60.23,48.07,22.56,44.44,18.08,135,18.08,135,21.22-12.3,91.31-54.58,91.31-54.58-8.72-56.33-40.68-160.89-116-164.41-35.74-1.61-45.42,17.11-53.62,35.92h0Zm0,0"
    fill="#1e196f"
  /><path
    d="M1214.08,1184.35c-10.85,35.67-31.26,77.91-28.59,134.65,2.67,56.74,39.91,335.13,40.67,351.33l28.37-1.32c-.89-18.92,22.52-250.16-5.34-338.87,26.94-72.45,60.47-128.79,60.47-128.79l-95.58-17Zm0,0"
    fill="#c9d8f7"
  /><path
    d="M1240.75,1707.1c4.27-.2,9.79,4.28,10.7,9.27l7.77-.37c4.27-9.29,5.05-19.8,2.21-29.62-3-10.31-5.58-7.69-8.17-4.85-5.94,5.31-31.07,8.16-33.45-.23-6.47,1.29-72.57,23.72-72.28,29.91,.47,10,8.35,9.65,13.71,9.39,63.38-2.98,54.76-6.48,79.51-13.5h0Zm0,0"
    fill="#3730a3"
  /><path
    d="M1226.16,1670.33c1.73-9.65,29-18.12,28.37-1.32-.21,3.63,.45,7.25,1.94,10.57-6,5.31-62.82,25.87-72.92,13.93,6.45-3.87,43.08-13.05,42.61-23.18h0Zm197.71-413.01h.05l14.19-68.56-111.71-1.58s21.93,76.5,31.71,98.81c2.24,5.09,5.45,9.69,9.45,13.54,40.87,39.44,249.43,208.91,261.1,220.17l19.72-20.43c-13.45-13.01-153.96-193.61-224.51-241.95h0Zm0,0"
    fill="#c9d8f7"
  /><path
    d="M1664.5,1536.41c3-3.07,10.06-3.59,14.14-.59l5.4-5.6c-3.26-9.69-9.89-17.87-18.7-23.07-9.28-5.44-9.34-1.77-9.27,2.07-.68,8-17,27.25-24.51,22.8-3.82,5.37-36.53,67-32.07,71.33,7.23,7,12.69,1.3,16.42-2.57,44.09-45.65,35.43-42.31,48.59-64.37h0Zm0,0"
    fill="#3730a3"
  /><path
    d="M1628.66,1519.67c-5.37-8.21,8.68-33.08,19.72-20.43,2.34,2.79,5.32,4.98,8.68,6.37-.68,7.94-28,61.91-43.51,60.16,2.06-7.26,22.45-39.06,15.11-46.1h0Zm0,0"
    fill="#c9d8f7"
  /><path
    d="M1457,840.4c-18.92,0-135.54,5.28-135.54,5.28-69.64,34.48-88.31,223.61-199.2,293.93,87.9,54.09,151.46,76.4,234.62,82.48,76.87,5.63,121.64-.12,214.93-56.92-30.76-16.56-57.14-53.8-76-104.42-25.75-68.87,12.93-154.31-38.81-220.35h0Zm0,0"
    fill="#fcde06"
  /><path
    d="M438.6,532.98c33.61,15,90.67,69.23,114.57,197.32,23.87,14.4,39,23.49,52.16,30.65-1.39,10.5-3.44,26.12-5.91,45.73-30,2.53-92.35-29.06-92.35-29.06l-60.88-250.31"
    fill="#edd000"
  /><path
    d="M335.7,902.5c-16.11,105.15,112.11,274.2,147.19,404.66-21,122.77-7.42,203.16,10.24,344.64h34.32c15.17-87.44,25.93-152.61,51.35-327.1,7.63-52.36-16-274.62-61.91-422.2h-181.19Zm0,0"
    fill="#1e196f"
  /><path
    d="M488,1664.36c4.5-6.92,5.15-12.56,5.15-12.56h34.32c-4.83,9.7-2.54,18-1.21,22.84-8.95,3.42-22.01-5.42-38.26-10.28h0Zm0,0"
    fill="#c0d0f0"
  /><path
    d="M488,1664.36c16.27,4.86,29.33,13.7,38.26,10.28,4.71,3.42,74.3,28.46,81.4,33.3,7.1,4.84,6.18,12.66-1.5,12.66-11.21,0-103.62-4.06-103.62-4.06-1.88,2-3.4,4.06-3.4,4.06h-21.14c-6.3-10.92-4.59-43.72,10-56.24h0Zm0,0"
    fill="#1e196f"
  /><path
    d="M309.14,902.5c-27,138.2,2.94,274.2,38,404.66-32.23,81.78-36.43,187.33-55.55,344.64h34.31c29.83-72.78,73.29-176.72,135.85-327.1,20.34-48.85,22.53-284.63,13.86-422.2h-166.47Zm0,0"
    fill="#3730a3"
  /><path
    d="M286.44,1664.36c4.51-6.92,5.17-12.56,5.17-12.56h34.31c-4.83,9.7-2.54,18-1.22,22.84-8.92,3.42-21.98-5.42-38.26-10.28h0Zm0,0"
    fill="#c0d0f0"
  /><path
    d="M286.44,1664.36c16.28,4.86,29.34,13.7,38.26,10.28,4.72,3.42,74.32,28.46,81.4,33.3,7.25,5,6.19,12.66-1.49,12.66-11.21,0-103.62-4.06-103.62-4.06-1.88,2-3.4,4.06-3.4,4.06h-21.12c-6.29-10.92-4.59-43.72,9.97-56.24h0Zm0,0"
    fill="#1e196f"
  /><path
    d="M377,530.45c-48.33,16.47-58,38.33-70.5,125.58-12.5,87.25,2.6,246.47,2.6,246.47h207.79c8.64-52.67,17.68-182.79,0-264.56-17.68-81.77-57.57-97.49-70.7-110.63-38.47,0-69.19,3.14-69.19,3.14h0Zm0,0"
    fill="#fcde06"
  /><path
    d="M439.83,501.61c1.82,10.41,6.36,25.7,6.36,25.7-4.49,6.1-32.23,21.14-64.35,2.92,2.32-19.11,.78-63.44,.78-63.44l57.21,34.82Zm0,0"
    fill="#c8d6f2"
  /><path
    d="M487.76,402.77c-.23,20.26,.67,81.13-15.09,99.83-12.93,15.36-33,14.36-62.24-2.59-29.24-16.95-19.1-74.21-19.92-91.48-1.28-27.36,97.58-35.15,97.25-5.76h0Zm0,0"
    fill="#e5e7eb"
  /><path
    d="M496.49,381.18c9.45-8.35,36.71-43.89,9.32-32.41,7.19-10.89-5.35-42.07-31.81-8.89-26.46,33.18-52.47,18.46-52.47,18.46,9.1-4.89,20.22-34.11-13.75-9.12-33.97,24.99-46.07,36.97-36.56,62.66,9.51,25.69,.6,85.64,10.3,94.3,4.54,4.07,11.09-6.27,10.5-30.43-.54-22.85,15.94-9.91,13.7-29-1.01,2.04-2.95,3.45-5.2,3.78-4.17,.44-8-3.62-8.61-9.08-.61-5.46,2.34-10.23,6.52-10.67,1.79-.14,3.56,.46,4.89,1.66-3-17-4.84-31.61,4.55-36.58,11.62-6.14,70.06,14.37,89.37,.92,19.31-13.45,11.82-20.14-.75-15.6h0Zm0,0"
    fill="#1e196f"
  /><path
    d="M424.88,612.31c-22-62.7-70.68-75.41-88.53-42-23.29,43.58,47.47,218.15,105,243.81,88.89,39.68,212.66,42,212.66,42-.06-14.5,.85-35.79,.85-35.79-26-.64-119.28-53.44-164.75-76-17.11-8.45-40.52-61.59-65.23-132.02h0Zm0,0"
    fill="#fef08a"
  /><g clip-path="url(#b)"><path
    d="M654.82,820.29c9.71,1.16,9.49-7.37,20.4-12,10.35-4.36,16.12-17,18.94-14.66,2.82,2.34-4.71,16.38-7.36,19,6.46-1.08,42.48-9.59,45-2,2.08,6.19,1.81,10.89-.14,18.26-1.95,7.37-12.35,20.09-27.77,23.3-15.26,3.17-22.6,2.46-30.89,.25-8.78-2.35-19-1.1-19-1.1,0-5.05,.82-31.05,.82-31.05h0Zm0,0"
    fill="#c8d6f2"
  /></g></svg>
</template>