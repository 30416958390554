<template>
  <svg viewBox="0 0 877.38 1209.64"><path
    d="M276.4,412.11c-2.22,1.34-3.53,3.79-3.41,6.39,.05,3.78,3.15,6.81,6.93,6.77l61.47-2.29h0c3.78-.24,6.65-3.49,6.41-7.27-.07-2.59-1.55-4.94-3.87-6.12,1.11-.68,2.01-1.65,2.6-2.81,1.83-3.52,.45-7.86-3.07-9.69,2.22-1.34,3.53-3.79,3.41-6.39-.05-3.78-3.15-6.82-6.93-6.78l-61.47,2.29c-3.78,.24-6.65,3.49-6.41,7.27,.07,2.59,1.55,4.94,3.87,6.12-2.22,1.34-3.53,3.79-3.41,6.38,.07,2.59,1.56,4.94,3.87,6.11Z"
    fill="#6366f1"
  /><path
    d="M279.93,425.27s5.56,11.31,7.14,12.23c1.58,.92,2.56,.89,5.13,.79,2.57-.1,18.97-.7,18.97-.7,0,0,16.4-.61,18.97-.71,2.57-.1,3.55-.13,5.06-1.17,1.51-1.04,6.21-12.73,6.21-12.73l-30.73,1.14-30.74,1.14Z"
    fill="#5255cc"
  /><path
    d="M251.1,347.76c.1,.1,.65,.71,.87,.9,17.7,16.93,26.32,32.22,26.51,37.56l30.74-1.14,30.73-1.15c-.2-5.35,7.21-21.31,23.66-39.55,.17-.18,.78-.83,.91-1,11.2-14.03,16.99-31.62,16.32-49.56-1.54-41.38-36.33-73.67-77.71-72.13-41.38,1.54-73.67,36.33-72.13,77.71,.64,18.01,7.78,35.19,20.1,48.35Z"
    fill="#fef08a"
  /><path
    d="M282.2,386.08c-2.21-9.3-11.54-23.29-25.58-37.1l-2.14-2.26c-24.36-25.82-25.84-65.68-3.47-93.23,24.65-30.36,69.25-34.99,99.6-10.34,30.36,24.65,34.99,69.24,10.34,99.6l-1.96,2.41c-12.98,14.82-21.27,28.65-22.76,38.91l-54.04,2.01Z"
    fill="#fcde06"
  /><path
    d="M257.37,246.66c-28.43,26.83-29.72,71.63-2.89,100.06l2.14,2.26c14.04,13.81,23.37,27.8,25.58,37.1l16.05-.59c-2.38-10-12.4-25.04-27.49-39.88l-2.3-2.43c-12.66-13.41-21.98-30.88-22.66-49.33-.74-19.71,6.22-38.93,19.41-53.6-2.76,1.94-5.38,4.08-7.84,6.4Z"
    fill="#edd000"
  /><path
    d="M350.2,252.51c-3.1,1.94-2.89,7.84,.45,13.17,3.35,5.33,8.57,8.08,11.67,6.13,3.1-1.95,2.89-7.84-.46-13.17-3.34-5.33-8.57-8.08-11.66-6.13Z"
    fill="#f9fafb"
  /><path
    d="M284.61,302.94c-14.49-8.87-13.89-10.18-13.4-11.24,.68-1.47,3.05-1.28,10.59,1.06-10.55-12.01-9.93-13.63-9.59-14.52,.22-.57,.76-.96,1.37-1,2.47-.26,9.45,4.64,13.96,8.57-5.48-13.61-4.8-15.49-3.48-16.22,1.06-.58,2.64-1.45,13.11,13.17-3.12-16.92-1.55-17.29-.38-17.57,2.7-.65,6.8,9.41,9.23,16.16,.13-16.4,1.47-16.51,2.68-16.6,2.08-.16,4.28,6.89,6.58,20.97,5.15-18.19,6.83-18.63,7.77-18.88,.52-.14,1.08,.02,1.45,.42,1.99,2.03,.82,15.45-.05,21.93,13.86-14.04,15.06-12.92,16.04-12.03,3,2.78-12.77,23.84-12.94,24.03h0c-.37,.42-1.01,.46-1.43,.09-.42-.37-.46-1.01-.08-1.43,1.92-2.17,11.93-17.07,12.87-20.85-1.29,.65-4.97,3.33-15.24,13.91-.26,.27-.65,.37-1.01,.27-.54-.15-.86-.72-.7-1.26,.9-3.17,2.29-19.26,1.32-22.74-.86,1.21-2.95,5.62-7.27,21.62-.11,.39-.43,.68-.83,.74-.55,.08-1.07-.3-1.16-.85-1.21-8.12-3.56-20.09-5.07-23.29-.41,1.64-.99,6.38-.87,20.12,0,.46-.3,.86-.74,.98-.54,.15-1.1-.16-1.26-.7-1.8-6.42-6.61-17.87-8.65-20.25-.19,1.41-.08,5.8,2.65,19.47,.08,.4-.09,.8-.42,1.03-.46,.32-1.09,.21-1.41-.26-8.94-12.86-12.08-15.62-13.07-16.21-.04,1.16,.67,5.07,6.25,18.25,.19,.46,.03,.98-.39,1.25-.47,.3-1.1,.17-1.41-.3-1.95-3-12.58-10.83-15.41-11.48,.36,1.08,2.25,4.53,11.57,14.87,.24,.27,.32,.64,.21,.98-.17,.54-.74,.84-1.28,.67l-1.2-.38c-2.04-.66-7.73-2.48-9.97-2.65,.93,.99,3.73,3.29,12.13,8.43,.42,.26,.6,.79,.41,1.25-.21,.52-.8,.77-1.32,.56-.05-.02-.1-.05-.15-.08Z"
    fill="#f9fafb"
  /><line
    x1="739.63"
    y1="351.23"
    x2="722.69"
    y2="333.5"
    fill="none"
    stroke="#1e196f"
    stroke-linecap="round"
    stroke-linejoin="round"
  /><line
    x1="752.1"
    y1="349.66"
    x2="764.13"
    y2="328.3"
    fill="none"
    stroke="#1e196f"
    stroke-linecap="round"
    stroke-linejoin="round"
  /><path
    d="M720.6,333.52c-.13-1.01,9.97-3.11,22.56-4.69,12.58-1.58,22.89-2.04,23.01-1.03,.13,1.01-9.97,3.11-22.56,4.69-12.58,1.58-22.89,2.04-23.01,1.03Z"
    fill="#1e196f"
  /><path
    d="M583.04,150.31c2.7,21.76,10.62,42.54,23.08,60.58h0c.04,.05,.08,.1,.11,.15,3.36,4.85,7.04,9.48,11,13.86,25.09,29.82,63.21,65.79,85.63,88.54h0c-.35-2.82,16.27-7.23,37.13-9.85L704.78,23.03c-74.88,9.39-131.14,52.4-121.75,127.28Z"
    fill="#9c9eff"
  /><path
    d="M854.2,116.28c-9.39-74.88-74.54-102.65-149.42-93.25l35.2,280.56c20.86-2.62,38.06-2.45,38.41,.37h0c16.11-27.59,44.16-71.86,61.11-106.95,2.76-5.22,5.17-10.62,7.23-16.15,.02-.06,.05-.12,.07-.18h0c7.62-20.56,10.16-42.65,7.4-64.4Z"
    fill="#e0e7ff"
  /><path
    d="M704.78,23.03c-66.18,8.3-115.39,50.42-106,125.3,2.63,21.57,9.9,42.31,21.31,60.8h0c.03,.05,.07,.1,.1,.15,3.03,4.86,6.35,9.54,9.94,13.99,22.64,30.12,56.92,66.57,77.11,89.61h0c-.35-2.82,14.3-6.99,32.74-9.3L704.78,23.03Z"
    fill="#f9fafb"
  /><path
    d="M704.78,23.03c-51.99,6.52-89.69,47.2-80.3,122.08,2.57,21.3,8.8,41.99,18.42,61.16h0c.03,.05,.06,.1,.08,.16,2.49,4.88,5.23,9.62,8.21,14.21,18.66,30.62,46.67,67.86,63.2,91.35h0c-.35-2.82,11.1-6.58,25.58-8.4L704.78,23.03Z"
    fill="#9c9eff"
  /><path
    d="M704.78,23.03c-25.99,3.26-42.63,41.29-33.23,116.17,2.55,20.96,6.94,41.65,13.13,61.83h0c.02,.05,.04,.11,.05,.16,1.6,5.09,3.28,9.96,5.05,14.61,11.37,31.54,27.89,70.22,37.73,94.55h0c-.35-2.82,5.23-5.85,12.47-6.76L704.78,23.03Z"
    fill="#f9fafb"
  /><path
    d="M704.78,23.03c66.18-8.3,124.27,20.35,133.66,95.23,2.77,21.55,.86,43.44-5.63,64.18h0c-.02,.06-.04,.12-.06,.17-1.73,5.46-3.79,10.81-6.18,16.02-14.5,34.78-38.71,78.57-52.58,105.88h0c-.35-2.82-15.59-3.23-34.02-.92L704.78,23.03Z"
    fill="#6366f1"
  /><path
    d="M704.78,23.03c51.99-6.52,98.57,23.58,107.97,98.46,2.77,21.27,1.85,42.86-2.74,63.82h0c-.01,.05-.03,.11-.04,.17-1.21,5.34-2.69,10.61-4.45,15.8-10.52,34.28-28.46,77.29-38.68,104.14h0c-.35-2.82-12.38-3.64-26.86-1.82L704.78,23.03Z"
    fill="#e0e7ff"
  /><path
    d="M704.78,23.03c25.99-3.26,51.51,29.48,60.9,104.36,2.71,20.94,3.56,42.07,2.55,63.15h0c0,.05,0,.11-.01,.17-.29,5.33-.72,10.46-1.28,15.4-3.22,33.37-9.68,74.93-13.21,100.94h0c-.35-2.82-6.51-4.37-13.75-3.46L704.78,23.03Z"
    fill="#6366f1"
  /><path
    d="M702.86,313.44h0c9.28,9.42,15.88,16.57,17.74,20.09-.13-1.01,9.97-3.11,22.56-4.69l-3.17-25.25c-20.86,2.62-37.48,7.03-37.13,9.85Z"
    fill="#5255cc"
  /><path
    d="M778.4,303.96c-.35-2.82-17.55-2.99-38.41-.37l3.17,25.25c12.58-1.58,22.89-2.04,23.01-1.03,.94-3.87,5.56-12.43,12.23-23.85h0Z"
    fill="#453eaf"
  /><rect
    x="743.43"
    y="342.57"
    width="4.2"
    height="10.5"
    transform="translate(-37.52 95.57) rotate(-7.16)"
    fill="#453eaf"
  /><path
    d="M740.29,370.32l-1.96-15.6c-.28-2.21,1.29-4.23,3.5-4.5l7.89-.99c2.21-.28,4.23,1.29,4.5,3.5h0l1.96,15.6"
    fill="none"
    stroke="#453eaf"
    stroke-linecap="round"
    stroke-linejoin="round"
  /><path
    d="M738.3,384.32c.14,1.1,1.15,1.88,2.25,1.75l19.25-2.42c1.1-.14,1.88-1.15,1.75-2.25l-1.7-13.53-23.25,2.92,1.7,13.53Z"
    fill="#7d80fb"
  /><path
    d="M755.65,382.14c.14,1.1-.65,2.11-1.75,2.25l5.91-.74c1.1-.14,1.88-1.15,1.75-2.25l-1.7-13.53-5.91,.74,1.7,13.53Z"
    fill="#5255cc"
  /><line
    x1="356.88"
    y1="1183.96"
    x2="348.41"
    y2="1168.9"
    fill="none"
    stroke="#1e196f"
    stroke-linecap="round"
    stroke-linejoin="round"
  /><line
    x1="365.67"
    y1="1185.07"
    x2="377.62"
    y2="1172.58"
    fill="none"
    stroke="#1e196f"
    stroke-linecap="round"
    stroke-linejoin="round"
  /><path
    d="M346.98,1168.55c.09-.71,7.35-.38,16.22,.74,8.87,1.12,15.98,2.6,15.89,3.32-.09,.71-7.35,.38-16.22-.74s-15.99-2.6-15.89-3.32Z"
    fill="#1e196f"
  /><path
    d="M285.05,1019.42c-1.96,15.33-.18,30.9,5.18,45.4h0s.03,.08,.05,.12c1.45,3.9,3.15,7.7,5.09,11.39,11.92,24.74,31.67,55.96,43.01,75.41h0c.25-1.99,12.37-2.1,27.07-.24l24.96-197.72c-52.77-6.66-98.7,12.88-105.36,65.65Z"
    fill="#6366f1"
  /><path
    d="M476.15,1043.54c6.66-52.77-32.97-83.11-85.74-89.77l-24.96,197.72c14.7,1.85,26.41,4.97,26.16,6.96h0c15.82-16.03,42.71-41.36,60.4-62.36,2.8-3.08,5.39-6.34,7.76-9.76,.03-.04,.05-.07,.08-.11h0c8.79-12.71,14.38-27.35,16.3-42.68Z"
    fill="#ced7f8"
  /><path
    d="M390.4,953.77c-46.64-5.89-87.6,14.28-94.26,67.05-1.97,15.19-.63,30.62,3.93,45.24h0s.03,.08,.04,.12c1.22,3.85,2.67,7.62,4.34,11.29,10.2,24.52,27.24,55.4,37,74.65h0c.25-1.99,10.99-2.27,23.98-.63l24.96-197.72Z"
    fill="#f9fafb"
  /><path
    d="M390.4,953.77c-36.64-4.63-69.49,16.56-76.15,69.33-1.97,14.99-1.32,30.21,1.9,44.98h0s.02,.08,.03,.12c.85,3.76,1.89,7.48,3.13,11.14,7.39,24.17,20.02,54.49,27.21,73.42h0c.25-1.99,8.73-2.56,18.94-1.27l24.96-197.72Z"
    fill="#6366f1"
  /><path
    d="M390.4,953.77c-18.32-2.31-36.32,20.75-42.98,73.52-1.92,14.75-2.53,29.65-1.83,44.51h0v.12c.21,3.75,.51,7.38,.9,10.85,2.25,23.52,6.78,52.82,9.26,71.15h0c.25-1.99,4.59-3.08,9.69-2.43l24.96-197.72Z"
    fill="#f9fafb"
  /><path
    d="M390.4,953.77c46.64,5.89,81.3,35.6,74.64,88.37-1.87,15.2-7,29.81-15.05,42.84h0s-.05,.07-.07,.11c-2.14,3.43-4.48,6.72-7.01,9.86-15.98,21.22-40.16,46.89-54.4,63.12h0c.25-1.99-10.08-4.93-23.07-6.57l24.96-197.72Z"
    fill="#453eaf"
  /><path
    d="M390.4,953.77c36.64,4.62,63.19,33.31,56.53,86.08-1.82,15.01-6.22,29.59-13.01,43.1h0s-.04,.07-.06,.11c-1.76,3.44-3.69,6.78-5.8,10.01-13.17,21.57-32.93,47.8-44.6,64.35h0c.25-1.99-7.82-4.65-18.03-5.93l24.96-197.72Z"
    fill="#ced7f8"
  /><path
    d="M390.4,953.77c18.32,2.31,30.02,29.12,23.36,81.9-1.8,14.77-4.91,29.35-9.29,43.57h0l-.04,.11c-1.13,3.59-2.32,7.02-3.57,10.29-8.03,22.22-19.69,49.48-26.65,66.62h0c.25-1.99-3.68-4.12-8.79-4.77l24.96-197.72Z"
    fill="#453eaf"
  /><path
    d="M338.37,1151.73h0c4.69,8.05,7.95,14.09,8.61,16.81,.09-.71,7.35-.38,16.22,.74l2.25-17.79c-14.7-1.86-26.82-1.75-27.07,.24Z"
    fill="#6366f1"
  /><path
    d="M391.61,1158.45c.25-1.99-11.46-5.11-26.16-6.96l-2.25,17.79c8.87,1.12,15.98,2.6,15.89,3.32,1.32-2.48,5.97-7.52,12.52-14.15h0Z"
    fill="#453eaf"
  /><rect
    x="357.81"
    y="1181.19"
    width="7.4"
    height="2.96"
    transform="translate(-857.1 1393.31) rotate(-82.81)"
    fill="#453eaf"
  /><path
    d="M354,1197.12l1.39-11c.2-1.56,1.62-2.66,3.17-2.46h0l5.56,.7c1.56,.2,2.66,1.62,2.46,3.18h0l-1.39,11"
    fill="none"
    stroke="#453eaf"
    stroke-linecap="round"
    stroke-linejoin="round"
  /><path
    d="M350.2,1206.33c-.1,.78,.45,1.49,1.23,1.59l13.57,1.71c.78,.1,1.49-.45,1.59-1.23l1.2-9.54-16.39-2.07-1.2,9.54Z"
    fill="#7d80fb"
  /><path
    d="M362.42,1207.87c-.1,.78-.81,1.33-1.59,1.23l4.16,.53c.78,.1,1.49-.45,1.59-1.23l1.2-9.54-4.16-.53-1.2,9.54Z"
    fill="#5255cc"
  /><path
    d="M814.48,733.63c3.15,8.52,6.89,21.26,8.54,27.66-38.96,4.66-138.93,57.91-182.35,51.19-49.61-7.68-114.32-167.4-125.59-198.38,0,0,61.77-32.99,78.18-41.75,73.07,65.07,18.5,126.37,67.99,184.8,64.32-12.93,86.41-1.71,143.9-20.31,3.3-1.07,6.39-2.15,9.32-3.21Z"
    fill="#1e196f"
  /><path
    d="M876.84,791.94c-7.68,6.15-19.32-16.35-41.22-29.37-2.73-1.62-7.05-1.95-12.6-1.28-1.66-6.4-5.39-19.14-8.54-27.66,16.27-5.9,26.93-11.04,32.22-7.3,6.25,4.41,4.02,7.22,7.72,14.43,10.31,20.11,26.06,48.27,22.42,51.18Z"
    fill="#37318f"
  /><path
    d="M553.13,498.86s63.19,33.98,82.8,50.03c15.55,12.72,60.9,71.85,60.57,83.17-.33,11.32,12.78,24.9,14.36,24.08,1.58-.82-8.44-10.97-3.53-13.31,4.9-2.35,16.36,20.56,21.14,19.31,2.01-.53,6.43-1.43,7.42-13.99,.99-12.57-23.2-28.78-23.2-28.78,0,0-54.97-86.51-60.02-89.97-15.75-10.8-86.51-70.87-103.73-81.57l4.2,51.04Z"
    fill="#e5e7eb"
  /><path
    d="M553.27,476.87c-2.05-11.26-2.6-20.55-4.34-29.05-2.46-1.88-11.58-4.8-21.77-4.17-10.95,.68-22.06,1.86-33.55,3.1-7.39,.8-15.22,4.62-19.89,4.82-2.69,3.44-4.91,7.51-13.06,21.27-21.49,36.3-4.16,50.8,9.99,62.1,19.8,15.81,31.42,46.45,35.21,84.06l87.39-46.67c-31.01-53.56-36.4-75.68-39.99-95.47Z"
    fill="#fcde06"
  /><path
    d="M470.66,534.94c19.8,15.81,31.42,46.45,35.21,84.06l19.75-10.55c-1.43-16.78-6.79-41.73-16.56-58.18-12.5-21.05-57.79-26.81-48.87-61.83l-4.14-6.88c-13.95,29.98,1.65,43.03,14.61,53.37Z"
    fill="#edd000"
  /><path
    d="M360.1,333.7c-.09,3.75-1.87,105.57,14.18,116.88,22.59,15.93,74.4,40.26,86.89,41.9,12.49,1.64,24.65-6.32,26.81-19.59,2.15-13.28-1.28-20.81-11.76-21.17-18.8-.64-74.48-15.06-84.12-25.35-7.3-7.79-15.11-54.79-16.53-79.69-.01-2.96,.51-5.93,.29-8.54,.56-2.98,3.93-3.58,6.51-11.39,3.6-10.93,9.61-15.8,10.08-18.61,.75-4.44-10.38,5.49-10.38,5.49,2.21-5.97,6.86-24.54,3.91-25.38-2.94-.84-7.18,16.93-8.25,23.23,.97-5.76,3.52-26.47,2.2-25.42-3.86,3.04-6.7,18.13-7.65,23.7,.94-5.58,2.2-27.12,.44-27.42-1.27-.21-3.59,10.44-5.39,27.13-.2,1.82-1.7-18.86-4.01-17.22-1.76,1.25-2.73,31.37-3.23,41.44Z"
    fill="#e5e7eb"
  /><path
    d="M757.2,888.4c-39.38-13.54-176.54-8.27-213.65-33.3-41.62-28.07-40.67-203.26-37.67-236.09,0,0,70.16-37.47,87.39-46.67,56.3,56.45-27.03,123.66-7.54,241.45,63.7,15.68,104.59,36.54,164.53,44.2,4.35,.55,8.36,.92,12.05,1.2-.23,9.94-1.95,19.78-5.12,29.2Z"
    fill="#3730a3"
  /><path
    d="M791.66,938.37c-9.84,.11-10.52-23.02-24.79-44.13-1.54-2.29-4.89-4.2-9.67-5.85,3.16-9.42,4.88-19.27,5.12-29.2,15.43,1.16,25.2,.74,29.71,5.7,2.47,2.72,.87,6.73,1.36,11.54,2.07,20.09,2.7,61.89-1.72,61.94Z"
    fill="#4741aa"
  /><path
    d="M492.15,446.96c2.29-3.23,4.4-9.31,1.78-13.26-22.44,2.71-20.39-34.24-18.51-42.98,1.88-8.74,4.38-30.7,28.32-26.53,36.11,6.29,21.97,53.61,21.08,61.41-.89,7.8,.4,13.63,5.85,18.8,0,0-24.15,3.32-38.51,2.56Z"
    fill="#e5e7eb"
  /><path
    d="M655.83,445.9c-8.2,9.48-40.09-4.09-44.12-1.46-5.62,3.67,3.44,17.59-10.03,23.41-4.71,2.04-16.91-4.38-30.44-12.82h0c-7.7,1.12,4.73,7.56,3.15,13.7-2.73,10.66-17.86,7.15-22.38,.36-5-7.5-11.39-17-17.1-12.46-9.3,7.41-4.14,24.36-13.8,25.2-11.81,1.03-13.4-12.5-8.17-31.42,5.16-18.64-9.5-28.09-15.92-28.68-.1-.01-.19-.03-.29-.04h0c-2-.26-3.52-.74-4.4-1.73-7.05-7.99,8.39-23.76,3.68-27.21-3.7-2.72-6.92,5.86-8.65,4.98-5.17-2.64-.91-17.97-9.61-17.44-.42-21.6,20.14-38.39,44.82-27.14,14.94,6.81,22.56,22.83,46.72,16.86,23.48-5.8,43.31-10.18,46.96-1.81,2.63,6.02-20.21,4.89-19.02,10.86,1.33,6.67,26.37-8.4,38.74,10.14,6.88,10.31-16.12,21.1,3.85,30.32,8.46,3.9,24.2,16.91,16,26.39Z"
    fill="#453eaf"
  /><path
    d="M658.12,440.3c-4.18-19.92-32.72-16.06-42.38-26.79-9.66-10.73,4.62-24.61-23.01-23.22-11.78,.59-6.09-12.85-26.52-10.32-20.42,2.53-39.03-12.44-54.54-18.6-15.51-6.16-24.66,5.25-30.37,19.97h-.01c-.98-.82-2.25-1.21-3.53-1.07-.42-21.6,20.14-38.39,44.82-27.14,14.94,6.81,22.56,22.83,46.72,16.86,23.48-5.8,43.31-10.18,46.96-1.81,2.63,6.02-20.21,4.89-19.02,10.86,1.33,6.67,26.37-8.4,38.74,10.14,6.88,10.31-16.12,21.1,3.85,30.32,6.88,3.17,18.57,12.37,18.29,20.79Z"
    fill="#5255cc"
  /><path
    d="M574.4,468.73c-2.73,10.66-17.86,7.15-22.38,.36-5-7.5-11.39-17-17.1-12.46-9.3,7.41-4.14,24.36-13.8,25.2-11.81,1.03-13.4-12.5-8.17-31.42,5.16-18.64-9.5-28.09-15.92-28.68-.1-.01-.19-.03-.29-.04h0c-2-.26-3.52-.74-4.4-1.73-2.72-3.09-2.08-7.33-.55-11.58-.39,3.38-2.8,8.11,8.91,10.13,11.74,2.03,21.8,11.22,21.74,26.06-.04,8.26,4.31,1.47,14.01-.93,9.7-2.4,14.83,10.31,19.72,9.19,6.56-1.5,6.81-14.67,18.53-10.1,10.89,4.25,31.54,25.97,35.13,5.05h0c-.97,5.63,2.62,15.43-8.13,20.07-4.71,2.04-16.91-4.38-30.44-12.82h0c-7.7,1.12,4.73,7.56,3.15,13.7Z"
    fill="#3730a3"
  /></svg>
</template>